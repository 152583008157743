import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { API_URL, COLORS, TYPOGRAPHY } from '@landing/constants';
import { BreakpointsEnum } from '@landing/types';
import Link from 'next/link';
import { useLockScroll } from '@landing/hooks/useLockScroll';
import { useRouter } from 'next/router';
import { Button } from '@landing/ui/components';
import { WhatsApp } from '@landing/ui/iconComponents';
import apx from '@landing/constants/adaptivepx';

interface Props {
  role?: 'client' | 'therapist' | 'business';
  setIsActive: (state: boolean) => void;
}

const Menu: FC<Props> = ({ setIsActive }) => {
  const { lockScroll, unlockScroll } = useLockScroll();

  const router = useRouter();

  useEffect(() => {
    lockScroll();

    return () => unlockScroll();
  }, []);

  return (
    <Root>
      <LinksWrap
        onClick={(e: any) => {
          setIsActive(false);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <>
          <Link href={'#about'} passHref>
            <a>О нас</a>
          </Link>
          {/* <Link
              href={API_URL.allTherapists}
              passHref
            >
              <a>Каталог психологов</a>
        </Link> */}
          <Link href={'#reviews'} passHref>
            <a>Отзывы</a>
          </Link>
          <Link href={'#faq'} passHref>
            <a>FAQ</a>
          </Link>
          {/* {role !== 'therapist' && (
            <Link
              href={{ pathname: API_URL.therapist, query: router.query }}
              passHref
            >
              <a>Специалистам</a>
            </Link>
          )}
          {role !== 'client' && (
            <Link
              href={{ pathname: API_URL.client, query: router.query }}
              passHref
            >
              <a>Клиентам</a>
            </Link>
          )}
          {role !== 'business' && (
            <Link
              href={{ pathname: API_URL.business, query: router.query }}
              passHref
            >
              <a>Бизнесу</a>
            </Link>
          )} */}
        </>
      </LinksWrap>
      <ButtonsWrapper>
        <Button
          onClick={() =>
            router.push('https://pogovorim.online/sign-up/client')
          }
          styleType="outline"
          isLarge
        >
          Личный кабинет
        </Button>
        <Button
          onClick={() =>
            router.push(
              'https://api.whatsapp.com/send/?phone=%2B79198707755&text&type=phone_number&app_absent=0',
            )
          }
          styleType="outline"
          isLarge
        >
          <WhatsApp />
        </Button>
        <Button
          onClick={() => router.push(API_URL.allTherapists)}
          styleType="primary"
          isLarge
        >
          Подобрать психолога
        </Button>
      </ButtonsWrapper>

      <BottomContent>
        <Email href="mailto:prosto@pogovorim.online">
          prosto@pogovorim.online
        </Email>
      </BottomContent>
    </Root>
  );
};

export default Menu;

const Root = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: ${apx.l(122)};
  flex-direction: column;
  background: ${COLORS.dark100};
  animation-duration: 5s;
  display: flex;
  align-items: center;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 0;
  padding-right: 0;

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding-top: ${apx.m(122)};
  }
`;

const LinksWrap = styled.div`
  display: flex;
  padding: 0 !important;

  width: 100%;
  margin-bottom: ${apx.l(140)};
  flex-direction: column;

  a {
    color: ${COLORS.nativeBlack};
    font-size: ${apx.l(24)};
    line-height: ${apx.l(29)};
    font-weight: 500;
    padding: ${apx.l(16)} ${apx.l(50)};
  }

  a:last-child {
    border: none;
  }

  a:not(:last-of-type) {
    margin-right: 0;
    border-bottom: ${apx.m(1)} solid ${COLORS.primary300};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(140)};

    a {
      font-size: ${apx.m(24)};
      line-height: ${apx.m(29)};
      padding: ${apx.m(16)} ${apx.m(50)};
    }

    a:not(:last-of-type) {
      margin-right: 0;
      border-bottom: ${apx.m(1)} solid ${COLORS.primary300};
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${apx.l(13)};
  margin-bottom: ${apx.l(27)};

  button {
    width: ${apx.l(300)};
    border-radius: ${apx.l(90)};
    height: ${apx.l(60)};
    font-size: ${apx.l(18)};
    font-weight: 600;
    line-height: ${apx.l(22)};

    &:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: ${apx.l(25)};
        height: ${apx.l(25)};
      }
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    row-gap: ${apx.m(13)};
    margin-bottom: ${apx.m(27)};

    button {
      width: ${apx.m(300)};
      border-radius: ${apx.m(90)};
      height: ${apx.m(60)};
      font-size: ${apx.m(18)};
      line-height: ${apx.m(22)};

      &:nth-child(2) {
        svg {
          width: ${apx.m(25)};
          height: ${apx.m(25)};
        }
      }
    }
  }
`;

// const StyledButtons = styled(Buttons)`
//   width: 100%;
//   margin: 0 auto 110px auto;
//   padding-left: 22px;
//   padding-right: 22px;
//   display: flex;
//   flex-direction: column;
// `;

const BottomContent = styled.div`
  width: 100%;

  @media (max-width: ${BreakpointsEnum.md}px) {
    display: block;
  }
`;

const Email = styled.a`
  width: 100%;
  display: inline-block;
  text-align: center;

  ${TYPOGRAPHY.bodyMedium16};
  font-size: ${apx.l(15)} !important;
  line-height: ${apx.l(18)};
  margin-bottom: ${apx.l(30)};

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(15)} !important;
    line-height: ${apx.m(18)};
    margin-bottom: ${apx.m(30)};
  }
`;
