import { FC, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { TYPOGRAPHY, COLORS, API_URL } from '@landing/constants';
import { ButtonLink } from '@landing/ui/components';
import Container from '../Container';
import newClientBg from '@landing/assets/images/new-bg.svg';

import { BreakpointsEnum } from '@landing/types';
import { useClientSize } from '@landing/hooks';
import { useRouter } from 'next/router';
import { TherapistList } from './components/TherapistList';
import BlackStar from '@landing/ui/iconComponents/BlackStar';
import { ITherapist } from '@landing/types/home.page';
import apx from '@landing/constants/adaptivepx';

interface IClientHeroSectionProps {
  therapistList: ITherapist[];

  scrollToReviews: () => void;

  scrollToGuarantee: () => void;
}

const ClientHeroSection: FC<IClientHeroSectionProps> = (props) => {
  const { therapistList, scrollToReviews, scrollToGuarantee } = props;

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('s');
  const rootRef = useRef(null);
  const [, setTitleHeight] = useState(86);

  const router = useRouter();

  useEffect(() => {
    if (isMobile) setTitleHeight(44);
    if (!isMobile) setTitleHeight(86);
  }, [isMobile]);

  return (
    <Root ref={rootRef} id="hero">
      <StyledContainer>
        <Text>Хотите улучшить вашу жизнь?</Text>
        <Title>
          Онлайн консультации <br /> с психологами
        </Title>
        <StyledLabels>
          <LabelsWrapper onClick={scrollToReviews}>
            <LabelTitle>
              <BlackStar />
              4.98 из 5
            </LabelTitle>
            <LabelText>средняя оценка от клиентов</LabelText>
          </LabelsWrapper>
          <LabelsWrapper onClick={scrollToGuarantee}>
            <LabelTitleGuarantee>Гарантия качества</LabelTitleGuarantee>
            <LabelText>
              Бесплатно заменим психолога, если он вам не подойдет
            </LabelText>
          </LabelsWrapper>
        </StyledLabels>
        <StyledButtons>
          <StyledButtonLink
            isLarge
            href={API_URL.allTherapists}
            query={router.query}
            target="_blank"
            rel="noopener noreferrer"
          >
            Подобрать психолога
          </StyledButtonLink>
          {/* { !isMobile &&
              <StyledButtonLink
              isLarge
              styleType='outline'
              href={API_URL.allTherapists}
              query={router.query}
              target="_blank"
              rel="noopener noreferrer"
              >
                Каталог психологов
              </StyledButtonLink> } */}
        </StyledButtons>
      </StyledContainer>

      <TherapistList data={therapistList} />
    </Root>
  );
};

export default ClientHeroSection;

const Root = styled.section`
  background-color: ${COLORS.white};
  position: relative;
  overflow: hidden;
  background-image: url(${newClientBg.src});
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    background-position-y: 50px;
    background-position-x: -1272px;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    /* background-position-y: 50px;
    background-position-x: -1272px; */
    background-size: ${apx.m(2171)} ${apx.m(710)};
    background-position-y: ${apx.m(96)};
    background-position-x: -${apx.m(1224)};
  }
`;

const StyledContainer = styled(Container)`
  padding: ${apx.d(150)} ${apx.d(160)} ${apx.d(45)} ${apx.d(160)};
  position: relative;
  z-index: 2;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: ${apx.l(107)} 0 ${apx.l(40)} 0;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding: ${apx.m(96)} ${apx.m(20)} ${apx.m(41)} ${apx.m(20)};
  }
`;

const Text = styled.p`
  ${TYPOGRAPHY.bodySemibold16};

  margin-bottom: ${apx.d(30)};
  line-height: ${apx.d(19)};
  border-radius: ${apx.d(34)};
  margin: 0 auto;
  padding: ${apx.d(8)} ${apx.d(15)};

  background: ${COLORS.lightYellow100};
  color: ${COLORS.nativeBlack};
  width: max-content;
  text-align: center;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(14)};
    font-size: ${apx.l(12)};
    line-height: ${apx.l(15)};
    border-radius: ${apx.l(25)};
    padding: ${apx.l(8)} ${apx.l(15)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(19)};
    font-size: ${apx.m(13)};
    line-height: ${apx.m(16)};
    border-radius: ${apx.m(25)};
    padding: ${apx.m(8)} ${apx.m(15)};
  }
`;

const Title = styled.h1`
  ${TYPOGRAPHY.heading1Bold72};
  color: ${COLORS.nativeBlack};
  text-align: center;
  margin-bottom: ${apx.d(20)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(54)};
    line-height: ${apx.l(67)};
    margin-bottom: ${apx.l(14)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(34)};
    line-height: ${apx.m(42)};
    margin-bottom: ${apx.m(13)};
  }
`;

const LabelTitle = styled.h3`
  display: flex;
  align-items: center;
  position: relative;
  ${TYPOGRAPHY.bodySemibold20};
  font-weight: 700;
  z-index: 1;

  line-height: ${apx.d(25)};
  column-gap: ${apx.d(3)};

  &:before {
    content: '';
    width: 145%;
    height: ${apx.d(14)};
    background: ${COLORS.lightYellow};
    z-index: -1;
    bottom: ${apx.d(2)};
    position: absolute;
    left: ${apx.d(-20)};
  }

  svg {
    width: ${apx.d(16)};
    height: ${apx.d(16)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-weight: 700;
    font-size: ${apx.l(15)};
    line-height: ${apx.l(23)};
    column-gap: ${apx.l(3)};

    svg {
      width: ${apx.l(12)};
      height: ${apx.l(12)};
    }

    &:before {
      height: ${apx.l(10)};
      bottom: ${apx.l(2)};
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-weight: 700;
    font-size: ${apx.m(15)};
    line-height: ${apx.m(18)};
    column-gap: ${apx.m(3)};

    svg {
      width: ${apx.m(16)};
      height: ${apx.m(16)};
    }

    &:before {
      height: ${apx.m(11)};
      bottom: ${apx.m(2)};
    }
  }
`;

const LabelTitleGuarantee = styled.h3`
  display: flex;
  align-items: center;
  position: relative;
  ${TYPOGRAPHY.bodySemibold20};
  font-weight: 700;
  z-index: 1;

  line-height: ${apx.d(25)};
  column-gap: ${apx.d(3)};

  &:before {
    content: '';
    width: 110%;
    height: ${apx.d(14)};
    background: ${COLORS.lightYellow};
    z-index: -1;
    bottom: ${apx.d(2)};
    position: absolute;
    left: ${apx.d(-7)};
  }

  svg {
    width: ${apx.d(16)};
    height: ${apx.d(16)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-weight: 700;
    font-size: ${apx.l(15)};
    line-height: ${apx.l(23)};
    column-gap: ${apx.l(3)};

    svg {
      width: ${apx.l(12)};
      height: ${apx.l(12)};
    }

    &:before {
      height: ${apx.l(10)};
      bottom: ${apx.l(2)};
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-weight: 700;
    font-size: ${apx.m(15)};
    line-height: ${apx.m(18)};
    column-gap: ${apx.m(3)};

    svg {
      width: ${apx.m(16)};
      height: ${apx.m(16)};
    }

    &:before {
      height: ${apx.m(11)};
      bottom: ${apx.m(2)};
    }
  }
`;

const LabelText = styled.p`
  max-width: ${apx.d(138)};
  text-align: center;

  ${TYPOGRAPHY.bodyMedium16};
  font-size: ${apx.d(17)};
  text-transform: none !important;

  &:last-child {
    max-width: ${apx.d(264)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(13)} !important;
    line-height: ${apx.l(16)};
    max-width: ${apx.l(103)};

    &:last-child {
      max-width: ${apx.l(198)};
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(16)} !important;
    line-height: ${apx.m(18)};
    max-width: ${apx.m(175)};

    &:last-child {
      max-width: ${apx.m(240)};
    }
  }
`;

const StyledLabels = styled.div`
  display: flex;
  justify-content: center;

  column-gap: ${apx.d(43)};
  margin-bottom: ${apx.d(25)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    column-gap: ${apx.l(32)};
    row-gap: ${apx.l(18)};
    margin-bottom: ${apx.l(18)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    flex-direction: column;
    row-gap: ${apx.m(20)};
    margin-bottom: ${apx.m(21)};
  }
`;

const LabelsWrapper = styled.div`
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    p {
      max-width: ${apx.d(138)};
      text-align: center;
    }
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    &:first-child {
      p {
        max-width: ${apx.l(103)};
      }
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    &:first-child {
      p {
        max-width: 100%;
      }
    }
  }
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  column-gap: ${apx.d(26)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: 0;
    padding: 0;
    ${TYPOGRAPHY.bodySemibold20};
    font-size: ${apx.l(20)};
    border-radius: ${apx.m(17)};
  }
`;

// const StyledButtonLink = styled(ButtonLink)`
//   padding: ${ apx.d( 20 )} ${ apx.d( 82 )};
//   border-radius: ${ apx.d( 67 )};
//   line-height: ${ apx.d( 22 )};
//
//   ${TYPOGRAPHY.bodyMedium18};
//   font-weight: 600;
//
//   @media (max-width: ${BreakpointsEnum.tablet}px) {
//
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-bottom: 0;
//     padding: 0;
//     ${TYPOGRAPHY.footnoteMedium13};
//     font-weight: 600;
//
//     width: ${ apx.l( 262 )};
//     font-size: ${ apx.l( 13 )};
//     line-height: ${ apx.l( 17 )};
//     border-radius: ${ apx.l( 51 )};
//     height: ${ apx.l( 45 )};
//
//   }
//
//   @media (max-width: ${BreakpointsEnum.mb}px) {
//
//     height: ${ apx.m( 50 )};
//     font-size: ${ apx.m( 16 )};
//     line-height: ${ apx.m( 20 )};
//     border-radius: ${ apx.m( 51 )};
//     max-width: ${ apx.m( 275 )};
//     width: 100%;
//
//   }
//
// `;

export const StyledButtonLink = styled(ButtonLink)`
  padding: ${apx.d(19)} ${apx.d(53)};
  border-radius: ${apx.d(67)};
  line-height: ${apx.d(22)};

  ${TYPOGRAPHY.bodyMedium18};
  font-weight: 600;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: ${apx.d(12)} ${apx.d(33)};
    ${TYPOGRAPHY.footnoteMedium13};
    font-weight: 600;

    max-width: ${apx.m(150)};
    width: 100%;
    line-height: ${apx.m(10)};
    border-radius: ${apx.l(51)};
    height: ${apx.m(30)};
    font-size: ${apx.m(9)};
  }

  @media (max-width: ${BreakpointsEnum.s}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 0;
    ${TYPOGRAPHY.footnoteMedium13};
    font-weight: 600;

    max-width: ${apx.m(275)};
    width: 100%;
    line-height: ${apx.m(20)};
    border-radius: ${apx.l(51)};
    height: ${apx.m(50)};
    font-size: ${apx.m(16)};
  }
`;

// const StyledOutlineLink = styled(ButtonLink)`
//
//   margin-bottom: 0;
//   padding: 19px 53px;
//   border-radius: 67px;
//   ${TYPOGRAPHY.bodyMedium18};
//   font-weight: 600;
//   line-height: 22px;
//   height: 62px;
//   font-size: 14px;
//
//   @media (max-width: ${BreakpointsEnum.sm}px) {
//
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 262px;
//     margin-bottom: 0;
//     padding: 0;
//     ${TYPOGRAPHY.footnoteMedium13};
//     font-weight: 600;
//     line-height: 16.5px;
//     border-radius: 51px;
//     height: 44.5px;
//     font-size: 14px;
//   }
//
// `;

// const StyledText = styled.p`
//   ${TYPOGRAPHY.bodySemibold20};
//   color: ${COLORS.dark700};

//   @media (max-width: ${BreakpointsEnum.sm}px) {
//     ${TYPOGRAPHY.bodyRegular16};
//   }
// `;

// const StyledTextWrap = styled.div`
//   width: 100%;
//   max-width: 272px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   @media (max-width: ${BreakpointsEnum.sm}px) {
//     max-width: 230px;

//     svg {
//       width: 166px;
//       height: 5px;
//     }
//   }
// `;

// const StyledBannerThoughtfulSuggestion = styled(BannerThoughtfulSuggestion)`
//   margin-top: 80px;

//   @media (max-width: ${BreakpointsEnum.md}px) {
//     margin-top: 40px;
//   }
// `;
