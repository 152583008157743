import { VARS } from './vars';

export const API_URL = {
  allTherapists: `${VARS.baseAppUrl}/all-therapists`,
  questionnaire: `${VARS.baseAppUrl}/client/interview`,

  login: `${VARS.baseAppUrl}/login`,
  loginBusiness: `${VARS.baseAppUrl}/login/business`,
  clientSignUp: `${VARS.baseAppUrl}/sign-up/client`,
  therapistSignUp: `${VARS.baseAppUrl}/sign-up/therapist`,

  termsOfUse: '/',
  privacyPolicy: '/assets/documents/privacy-policy.pdf',
  userTermsOfUse: '/assets/documents/public-offer-user.pdf',
  expertTermsOfUse: '/assets/documents/public-offer-expert.pdf',
  promocodes: '/assets/documents/promocodes.pdf',

  therapist: '/therapists',
  business: '/business',
  client: '/',

  articlesList: `${VARS.blogAppUrl}/api/articles`,
  article: (url: string | string[]) => `${VARS.blogAppUrl}/api/articles/${url}`,
  articlesTagsList: `${VARS.blogAppUrl}/api/tags`,
  sendComment: `${VARS.blogAppUrl}/api/comments`,

  requestCompany: `${VARS.baseUrl}/companies`,

  getTherapistsWithParams: `${VARS.baseUrl}/therapists/search/with_filter?limit=10&page=1`,
  therapistsDetailPage: (id: string) => `${VARS.baseAppUrl}/therapist/${id}`,
};
