import { FC, useState, useEffect } from 'react';
import Image from 'next/image';
import { useClientSize } from '@landing/hooks';
import WhyImg from '../../assets/images/whyWe.jpg';
import PlayIcon from '../../assets/svg/play-icon.svg';
import ChevronDown from '@landing/ui/iconComponents/ChevronDown';
import {
  WhyContentWrapper,
  StyledContainer,
  Root,
  ImageBox,
  ImageInfo,
  ImageTitle,
  ImageDescr,
  InfoBox,
  WhyTitle,
  WhyText,
  WhyDescr,
  SectionTitle,
} from './styles';

interface Props {
  className?: string;
  setVideoState: (state: boolean) => void;
}

const WhyWeSection: FC<Props> = ({ className, setVideoState }) => {
  const [isResize, setResize] = useState(false);
  const { getIsBreakpoint } = useClientSize();
  const isLaptop = getIsBreakpoint('tablet');
  const isMobile = getIsBreakpoint('mb');

  const shortedText = [
    `Более 12 лет мы с командой занимаемся психологией и развитием индустрии. `,
    `В 2020 году были открыты двери нашего первого психологического центра в Москве. `,
    `За 5 лет мы масштабировались в большую структуру, которая сегодня включает в себя 2 оффлайн центра в Москве, Современную школу психологии и бизнес-консультирования, выдающую дипломы государственного образца, и платформу Поговорим.online. `,
    <>
      <br />
      <br />
      Поговорим.online - пространство с самыми высокими стандартами качества в
      индустрии, которые мы обеспечиваем благодаря накопленному опыту и самыми
      строгими <a href=" ">критериями отбора</a> психологов.
      <br />
      <br />{' '}
    </>,
    `Я, как никто другой, знаю, как сложно найти психолога, к которому не страшно прийти и можно доверять. Поверьте, мы нашли и отобрали для вас лучших специалистов. Приходите, поговорим! `,
  ];

  const shortedTextLength = isMobile ? 184 : 394;
  const shortedTextDots = isMobile ? '...' : '';

  const imgUrl = WhyImg.src;

  const ImageBoxBg = { backgroundImage: `url(${imgUrl})` };

  const handleResize = () => setResize(window.innerWidth <= 992);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id="about" style={{ scrollMargin: 100 }}>
      <Root className={className} id="why-us">
        <StyledContainer>
          <WhyContentWrapper>
            <SectionTitle>Почему нам доверяют?</SectionTitle>

            <ImageBox style={ImageBoxBg}>
              <ImageInfo>
                <ImageTitle>Нигина Абаева</ImageTitle>

                <ImageDescr>
                  Сооснователь платформы <br /> Психолог, гештальт-терапевт
                </ImageDescr>
              </ImageInfo>

              <button onClick={() => setVideoState(true)}>
                <Image src={PlayIcon} alt="play" />
              </button>
            </ImageBox>

            <InfoBox isActive={isResize}>
              <WhyTitle>Почему нам доверяют?</WhyTitle>

              <WhyText mobile={isResize && isMobile}>
                {(!isMobile && !isLaptop) ||
                ((isMobile || isLaptop) && !isResize) ? (
                  <WhyDescr>{shortedText}</WhyDescr>
                ) : (
                  <WhyDescr>
                    {shortedText.join(' ').slice(0, shortedTextLength) +
                      shortedTextDots}
                  </WhyDescr>
                )}
              </WhyText>

              <div onClick={() => setResize(!isResize)}>
                Читать полностью
                <ChevronDown
                  style={
                    !isResize ? { transform: 'rotate(180deg)' } : undefined
                  }
                />
              </div>
            </InfoBox>
          </WhyContentWrapper>
        </StyledContainer>
      </Root>
    </div>
  );
};

export default WhyWeSection;
